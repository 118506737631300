.login {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  outline: none;

  // Graphics on the left
  &__graphics {
    width: 40%;
    min-width: 200px;
    background-color: $color-blue;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 30px;
  }

  // Login form on the right
  &__container {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    font-family: 'Neo Sans Std', sans-serif;

    & > *:not(:last-child) {
      margin-bottom: 34px;
    }
  }

  &__title {
    display: block;
    font-size: 50px;
  }

  &__submit {
    border: none;
    border-radius: 10px;
    background-color: $color-blue;
    color: #fff;
    padding: 15px 40px;
    font-weight: normal;
  }
}
