.loader-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  i {
    font-size: 80px;
    color: $btn-grey;
    margin: 30px;
  }
  .message-holder {
    font-weight: lighter;
    color: $text-grey;
  }
}
