.errorHandler {

  &__text {
    margin-bottom: 10px;
    font-size: 14px;
    font-family: OpenSans, sans-serif;
    font-weight: normal;
    color: $color-red;
  }
}
