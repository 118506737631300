.btn-black {
  background-color: rgb(62,69,84);
  border: 1px solid rgb(44, 51, 66);
  padding: 16px;
  color: $white;
  i {
    color: $text-grey;
    padding-left: 10px;
    font-size: 16px;
  }
}
.btn-orange {
  background-color: $tags-orange;
  border: none;
  padding: 5px;
  color: $white;
}
.btn-green {
  background-color: $green;
  padding: 12px;
  color: $white;
  border: 1px solid $green-border;
  height: 40px;
  width: 40px;
  i {
    font-size: 15px;
  }
}
.btn-blue {
  background-color: $blue;
  border: 1px solid $blue-border;
  color: $white;
  padding: 12px;
  height: 40px;
  width: 40px;
  i {
    font-size: 15px;
  }
}
.btn-blue-big {
  background-color: $blue;
  border: 1px solid $blue-border;
  color: $white;
  padding: 12px;
  //width: 100px;
  span {
    padding-left: 7px;
  }
  i {
    font-size: 15px;
  }
}
a {
  text-decoration: none;
  color: inherit;
}
