@import "../../assets/fonts/fontawesome/scss/fontawesome.scss";
@import "../../assets/fonts/fontawesome/scss/fa-regular.scss";
@import "../../assets/fonts/fontawesome/scss/fa-brands.scss";
@import "../../assets/fonts/fontawesome/scss/fa-solid.scss";

@font-face {
  font-family: 'Neo Sans Std';
  src: url('../../assets/fonts/neo-sans-std.otf');
}

@font-face {
  font-family: OpenSans;
  src: url('../../assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: OpenSans;
  src: url('../../assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

.fa-power-off {
  font-size: 24px;
  cursor: pointer;
}
