.field {
  position: relative;

  &__label {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 20px;
    pointer-events: none;
    transition: all .2s ease;
    color: $color-grey-8;
  }

  &__input {
    border:none;
    border-radius: 10px;
    padding: 30px 15px 15px 15px;
    font-family: OpenSans, sans-serif;
    font-weight: normal;
    font-size: 20px;
  }

  &__input:focus ~ &__label,
  &__input:not(:focus):valid ~ &__label {
    top: 10px;
    left: 14px;
    transform: translateY(0);
    font-size: 15px;
  }
}
