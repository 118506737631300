.main-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  min-width: 800px;
  margin: 0 auto;

  .MuiCheckbox-colorPrimary {
    color: #008dce;

    &.Mui-checked {
      color: #008dce;
    }
  }
  .MuiTypography-body1 {
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-weight: 700;
  }

  .class {
    width: 100%;

    .main-container-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $header-color;
      color: $white;
      margin: 50px 0 0 0;

      .classes-container {
        display: flex;
        justify-content: space-between;

        .content-holder {
          margin: 30px;

          span {
            padding-right: 80px;
          }

          div {
            font-size: 12px;
            font-weight: normal;
            line-height: 2;
          }
        }

        .status-holder {
          margin: 30px;
          font-size: 12px;

          span {
            padding-left: 35px;
            color: $blue;

            &.active {
              color: $green;
              padding-left: 0;
            }

            &.inactive {
              color: $text-grey;
              padding-left: 0;
            }
          }
        }
      }

      .content-options {
        margin: 30px;
        display: flex;
        //justify-content: flex-end;
        font-size: 12px;
        font-weight: bold;

        .btn-blue-big {
          border: 1px solid $blue-border;
          padding: 8px;
          width: 200px;
          height: 40px;
          margin-left: 0;
        }

        div:first-child {
          i {
            font-size: 15px;
          }
        }

        div:nth-child(2) {
          margin-left: auto;
        }
      }
    }

    .table-container {
      width: 100%;

      table {
        width: 100%;
        border: 1px solid rgb(231, 234, 239);
        font-weight: bold;
        white-space: nowrap;
        border-collapse: collapse;

        td {
          height: 80px;
          font-size: 14px;
          text-align: center;

          &:first-child {
            text-align: start;
            padding-left: 24px;
          }

          &:nth-child(2) {
            color: $text-grey;
            font-size: 12px;
          }

          &:nth-child(3) {
            color: $text-grey;
            font-size: 12px;
          }

          &:nth-child(4) {
            color: $text-grey;
            font-size: 12px;
          }

          &:last-child {
            text-align: end;
            padding-right: 30px;
          }
        }

        thead tr td {
          height: 50px;
          padding: 0 37px 0 37px;
          background-color: $table-grey;
          color: $text-grey;
          border-bottom: 1px solid rgb(231, 234, 239);
          font-size: 12px;

          i {
            padding-right: 10px;
          }
        }

        tbody tr:nth-child(odd) td {
          background-color: $white;
        }
      }

      .label {
        border-radius: 4px;
        padding: 6px 14px;
        font-size: 12px;
      }

      .active {
        background-color: rgb(223, 247, 225);
        color: rgb(0, 160, 13);
        border: 1px solid rgb(0, 160, 13);
      }

      .pending {
        background-color: rgb(249, 251, 231);
        color: rgb(130, 119, 23);
        border: 1px solid rgb(130, 119, 23);
      }

      .completed {
        background-color: rgb(238, 232, 250);
        color: rgb(86, 80, 95);
        border: 1px solid rgb(86, 80, 95);
      }
    }
  }

  button {
    margin-left: 8px;
    border-radius: 4px;
    cursor: pointer;
  }
}
