html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  min-width: 960px;
  font-family:  OpenSans, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: rgb(249, 249, 251);
}

button, input, textarea  {
  outline: none;
  font-family: OpenSans, sans-serif;
  font-weight: bold;
}

button, a {
  cursor: pointer;
}
