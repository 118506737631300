header {
  display: flex;
  flex-direction: column;
  background: $white;
  color: $text-grey;
  width: 100%;
  .header-logo {
    position: absolute;
    margin: 15px 30px;
  }
  .header-top {
    background: $header-color;
    width: 100%;
  }
  @media (max-width: 1284px) {
    .header-inner {
      background: $header-color;
      display: flex;
      justify-content: space-between;
      padding: 8px 0 8px 0;
      max-width: 850px;
      min-width: 400px;
      margin: 0 auto;
      ul {
        list-style: none;
        cursor: pointer;
      }
      li:hover {
        color: $white;
        cursor: pointer;
      }
      .menu {
        display: flex;
        align-items: center;
        li {
          text-align: center;
          padding-right: 24px;
          div {
            position: relative;
          }
          i {
            color: $white;
            position: absolute;
            font-size: 40px;
            display: block;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .user {
        display: flex;
        align-items: center;
        img {
          padding: 0 12px;
        }
      }
    }
  }
  @media (min-width: 1284px) {
    .header-inner {
      background: $header-color;
      display: flex;
      justify-content: space-between;
      padding: 8px 0 8px 0;
      max-width: 1140px;
      min-width: 500px;
      margin: 0 auto;
      ul {
        list-style: none;
        cursor: pointer;
      }
      li:hover {
        color: $white;
        cursor: pointer;
      }
      .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          text-align: center;
          padding-right: 24px;
          div {
            position: relative;
          }
          i {
            color: $white;
            position: absolute;
            font-size: 40px;
            display: block;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .user {
        width: 17%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .header-bottom {
    background-color: $white;
    width: 100%;
    .search {
      display: flex;
      align-items: center;
      padding: 30px 0 30px 40px;
      max-width: 1140px;
      min-width: 800px;
      margin: 0 auto;
      i {
        padding-right: 15px;
        color: $header-color;
      }
      input {
        width: 40%;
        height: 100%;
        border: none;
        font-size: 14px;
        font-weight: normal;
      }
      input:focus {
        outline: none;
      }
    }
  }
}
  .dropdown {
    position: absolute;
    top: 100%;
    padding: 5px 0;
    margin: -14px 22px 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    cursor: pointer;
    right: 0;
  }
