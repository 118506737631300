$white: #fff;
$header-color: rgb(53, 60, 75);
$text-grey: rgb(149, 156, 174);
$btn-grey: rgb(62,69,84);
$background-grey: rgb(249, 249, 251);
$table-grey: rgb(248, 249, 253);
$tags-orange: rgb(255, 171, 0);
$green: rgb(108, 189, 125);
$green-border: rgb(67, 165, 88);
$blue: rgb(1, 152, 219);
$blue-border: rgb(0, 141, 206);


// Colors from https://ant.design/docs/spec/colors
// Defaults are color-6
$color-blue: #1890ff;
$color-blue-5: #40a9ff;
$color-blue-4: #69c0ff;
$color-blue-3: #91d5ff;
$color-blue-2: #bae7ff;
$color-blue-1: #e6f7ff;

$color-green: #52c41a;
$color-green-5: #73d13d;
$color-green-4: #95de64;
$color-green-3: #b7eb8f;
$color-green-2: #d9f7be;
$color-green-1: #f6ffed;

$color-yellow: #fadb14;
$color-yellow-5: #ffec3d;
$color-yellow-4: #fff566;
$color-yellow-3: #fffb8f;
$color-yellow-2: #ffffb8;
$color-yellow-1: #feffe6;

$color-red: #f5222d;
$color-red-5: #ff4d4f;
$color-red-4: #ff7875;
$color-red-3: #ffa39e;
$color-red-2: #ffccc7;
$color-red-1: #fff1f0;

$color-purple: #722ed1;
$color-purple-5: #9254de;
$color-purple-4: #b37feb;
$color-purple-3: #d3adf7;
$color-purple-2: #efdbff;
$color-purple-1: #f9f0ff;

// Greys
$color-grey-8: #595959;
